import React, { useContext } from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { usePage } from "../context/PageContext"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_MEDIUM } from "../../constants"

const Container = styled.div`
  position: relative;
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  p {
    margin: 0 0 1rem;
    font-size: 1.3rem;
    text-align: center;
    line-height: 1.8rem;
  }
`

const Logos = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`

const Logo = styled.img`
  height: 2rem;
  margin: 0.6rem;

  ${MEDIA_MIN_MEDIUM} {
    margin: 1rem;
    height: 2.5rem;
  }
`

const PaymentLogos = () => {
  const { sanityShippingPaymentLogos } = usePage()
  const { currentStore } = useContext(BrinkContext)
  if (!sanityShippingPaymentLogos) return null

  const { checkoutLogos, checkoutText } = sanityShippingPaymentLogos
  const localizedlogos = checkoutLogos[currentStore.countryCode.toLowerCase()]
  const logos = localizedlogos?.length ? localizedlogos : checkoutLogos.default

  return (
    <Container>
      <p>{checkoutText[currentStore.languageCode] || checkoutText.en}</p>
      <Logos>
        {logos.map((logo) => (
          <Logo key={uuidv4()} src={logo.asset.url} />
        ))}
      </Logos>
    </Container>
  )
}

export default PaymentLogos
