import React, { useContext } from "react"
import styled from "styled-components"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import Dinero from "dinero.js"
import ColorSwatch from "../ui/ColorSwatch"

const Container = styled.div`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  width: 100%;
  padding: 2rem 2rem 2rem 0;
  position: relative;
  color: ${(p) => p.theme.colors.black};
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`

const Name = styled.div`
  margin: 0;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.2rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.4rem;
  }
`

const Price = styled.div`
  display: flex;
  font-size: 1.4rem;
  margin: 0.8rem 0 1rem 0;

  span {
    margin-right: 0.4rem;
  }
`

const Sku = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
  margin-top: 0.2rem;
`

const CurrentPrice = styled.div``

const OriginalPrice = styled.div`
  margin-left: 1rem;
  color: ${(p) => p.theme.colors.darkGrey};
  text-decoration: line-through;
`

const ChangeQty = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 0;
  display: flex;
  align-items: center;
`

const Qty = styled.div`
  width: 2.6rem;
  height: 2.6rem;
  margin: 0;
  padding: 0 0 0 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.black};
`

const QtyButton = styled.button`
  cursor: pointer;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background ${(p) => p.theme.colors.white};
  font-size: 1.2rem;
  transition: background 0.2s;
  padding: 0.1rem 0 0 0.1rem;

  &:hover {
    background: ${(p) => p.theme.colors.backgroundDark};
  }
`

const Delete = styled.span`
  position: absolute;
  top: 50%;
  margin-top: -1.5rem;
  left: -1.5rem;
  background: ${(p) => p.theme.colors.blackOpac};
  color: ${(p) => p.theme.colors.white};
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: ${(p) => p.theme.colors.black};
  }

  i {
    &:focus {
      outline: none;
    }
  }
`

const Image = styled.img`
  max-width: 8rem;
  display: block;
  margin-right: 2rem;
`

const Attributes = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-right: 1rem;
  }
`

const Item = ({ item, showControls, ...other }) => {
  const {
    cart,
    currentStore,
    plusOneProductVariantToCart,
    minusOneProductVariantToCart,
    removeProductFromCart,
    setIsLoading,
    languageCode
  } = useContext(BrinkContext)

  if (item.type === "shippingOption") return false
  const { id, imageUrl, attribute, quantity } = item
  const { displayName } = attribute

  const discount = cart.discounts.productDiscounts.find((d) => d.id === id)
  const priceDinero = toDinero(
    item.price[currentStore.currencyUnit],
    currentStore.currencyUnit
  )
  let discountDinero = null
  if (discount) {
    discountDinero = toDinero(
      discount.discountAmount[currentStore.currencyUnit],
      currentStore.currencyUnit
    )
  }
  const price = discountDinero
    ? priceDinero.subtract(discountDinero).toFormat()
    : null
  const originalPrice = priceDinero.toFormat()

  const size = item.customerAttribute["internationale Größe"]
  const color = item.customerAttribute["Farbe"]

  return (
    <Container {...other}>
      <Image src={imageUrl}></Image>
      {showControls && (
        <Delete>
          <i
            onClick={() => {
              setIsLoading(true)
              removeProductFromCart(id)
            }}
            onKeyDown={(event) => {
              if (event.key !== "Enter") return false
              removeProductFromCart(id)
              setIsLoading(true)
            }}
            className="fal fa-trash-alt"
            role="button"
            aria-label="Remove product from cart"
            tabIndex={0}
          ></i>
        </Delete>
      )}
      <div>
        <Name>{displayName[languageCode] || displayName.en}</Name>
        {item.customerAttribute && <Sku>{item.customerAttribute.SKU}</Sku>}
        <Price>
          {price ? (
            <>
              <CurrentPrice>{price}</CurrentPrice>
              <OriginalPrice>{originalPrice}</OriginalPrice>
            </>
          ) : (
            <CurrentPrice>{originalPrice}</CurrentPrice>
          )}
        </Price>

        <Attributes>
          {color && <ColorSwatch color={color} />}
          {size}
        </Attributes>

        {showControls && (
          <ChangeQty>
            <QtyButton
              onClick={() => {
                setIsLoading(true)
                minusOneProductVariantToCart(id)
              }}
              onKeyDown={(event) => {
                if (event.key !== "Enter") return false
                minusOneProductVariantToCart(id)
                setIsLoading(true)
              }}
              tabIndex={0}
            >
              <i className="fal fa-minus"></i>
            </QtyButton>
            <Qty>{quantity}</Qty>
            <QtyButton
              onClick={() => {
                plusOneProductVariantToCart(id)
                setIsLoading(true)
              }}
              onKeyDown={(event) => {
                if (event.key !== "Enter") return false
                plusOneProductVariantToCart(id)
                setIsLoading(true)
              }}
              tabIndex={0}
            >
              <i className="fal fa-plus"></i>
            </QtyButton>
          </ChangeQty>
        )}
      </div>
    </Container>
  )
}

const toDinero = (amount, currencyUnit) => {
  return new Dinero({ amount: amount, currency: currencyUnit })
}

export default Item
