import React from "react"
import styled from "styled-components"
import { MEDIA_MIN_LARGE } from "../../constants"

const StyledButton = styled.button`
  height: 5rem;
  cursor: pointer;
  border: none;
  padding: 0.2rem 3rem 0;
  background: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.2s;

  span {
    z-index: 20;
  }

  &:focus {
    outline: none;
  }

  ${MEDIA_MIN_LARGE} {
    &:hover {
      background: ${(p) => p.theme.colors.blackOpac};
    }
  }
`

const Button = ({ children, ...other }) => (
  <StyledButton {...other}>{children}</StyledButton>
)

export default Button
