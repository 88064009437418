import React, { useContext } from "react"
import Helmet from "react-helmet"
import i18n from "i18next"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "./context/BrinkContext"
import { getKlavyioApiKey } from "./context/utils/events/klaviyo"
import { useBrinkStoreConfig } from "./context/utils/useBrinkStoreConfig"

const Head = ({ meta, intSlug, slug }) => {
  const { title, description } = meta
  const { t } = useTranslation("translations")
  const defaultTitle = `${t("Rondure")} | Rondure`
  const defaultDescription = `${t("Rondure")} | Rondure`
  const metaTitle = title ? `${title} | ${defaultTitle}` : defaultTitle
  const metaDescription = description || defaultDescription
  const lang = i18n.language || window.localStorage.i18nextLng
  const { currentStore } = useContext(BrinkContext)
  const stores = useBrinkStoreConfig()

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: "hello@brinkcommerce.com"
        },
        {
          name: `twitter:title`,
          content: metaTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    >
      {stores
        .reduce(
          (a, b) =>
            !a.find((s) => s.languageCode === b.languageCode) ? a.concat(b) : a,
          []
        )
        .map((store) => {
          const uriSlug =
            (intSlug && intSlug[store.languageCode]) || slug || null
          const uri = uriSlug ? `${uriSlug}/` : ""
          if (store.languageCode === "en") {
            return (
              <link
                key={store.languageCode}
                rel="alternate"
                href={`https://www.rondure.com/${uri}`}
                hreflang={store.languageCode}
              />
            )
          }
          if (store.languageCode === "sv") {
            return (
              <link
                key={store.languageCode}
                rel="alternate"
                href={`https://www.rondure.se/${uri}`}
                hreflang={store.languageCode}
              />
            )
          }
          return (
            <link
              key={store.languageCode}
              rel="alternate"
              href={`https://www.rondure.${store.languageCode}/${uri}`}
              hreflang={store.languageCode}
            />
          )
        })}

      <meta
        name="facebook-domain-verification"
        content="sssazg84hi6ffxw7hoj3xt33ix0mvh"
      />

      <link rel="preconnect" href="https://kit.fontawesome.com/" async />
      <link rel="preconnect" href="https://kit-pro.fontawesome.com/" async />
      <link rel="preconnect" href="https://fonts.googleapis.com/" async />
      <link rel="preconnect" href="https://www.googletagmanager.com/" async />
      <link rel="preconnect" href="https://www.google-analytics.com" async />

      <script
        src="https://kit.fontawesome.com/9c2b3f2fd5.js"
        rel="preload"
        as="font"
        async
      />

      <script
        id="klavio-snippet"
        key="klavio-snippet"
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${getKlavyioApiKey(
          currentStore.countryCode
        )}`}
        type="text/javascript"
        async
      />
    </Helmet>
  )
}
export default Head
