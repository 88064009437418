import React from "react"
import styled from "styled-components"
import { ErrorMessage } from "react-hook-form"

const Container = styled.div`
  width: 100%;
  position: relative;
`

const Field = styled.input`
  height: 5rem;
  width: 100%;
  padding-left: 2rem;
  padding-bottom: 0.2rem;
  border: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(p) => p.theme.colors.black};
  }
`

const Label = styled.label`
  text-transform: uppercase;
  margin: 3.6rem 0 0.8rem;
  display: block;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
`

const Validation = styled.div`
  color: ${(p) => p.theme.colors.errors};
  display: block;
  position: absolute;
  border-top: 0.2rem solid ${(p) => p.theme.colors.errors};
  width: 100%;
  padding-top: 0.4rem;
  letter-spacing: 0;
  font-size: 1.3rem;
`

const Input = ({
  id,
  placeholder,
  label,
  type,
  name,
  contextValue,
  errors,
  autoFocus,
  ...other
}) => (
  <Container {...other}>
    {label && <Label htmlFor={name}>{label}</Label>}
    <Field
      id={id || name}
      name={name}
      defaultValue={contextValue}
      type={type || "text"}
      placeholder={placeholder}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
    />
    {errors && (
      <ErrorMessage errors={errors} name={name}>
        {({ message }) => <Validation>{message}</Validation>}
      </ErrorMessage>
    )}
  </Container>
)

export default Input
