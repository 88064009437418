import React from "react"
import styled from "styled-components"

const Simple = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 1.6rem;
  display: inline-block;
  position: relative;
  background: ${(p) => p.theme.swatches[p.bgColor]};
  border: 0.1rem solid ${(p) =>
    p.bgColor === "white" ? p.theme.colors.darkGrey : p.bgColor}};
`

const Container = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.black};
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 1.6rem;
`

const Dual = styled.div`
  overflow: hidden;
  transform: rotate(-45deg);
  width: 3rem;
  height: 3rem;
  border-radius: 1.6rem;
  background: ${(p) => p.theme.swatches[p.bgColorTwo]};

  span {
    height: 1.5rem;
    width: auto;
    border-radius: 0;
    background: ${(p) => p.theme.swatches[p.bgColorOne]};
    display: block;
    border-bottom: 0.1rem solid
      ${(p) => (p.bgColorTwo === "white" ? p.bgColorOne : p.theme.colors.white)};
  }
`

const SimpleSwatch = ({ bgColor, ...props }) => (
  <Simple bgColor={bgColor} {...props} />
)

const DualSwatch = ({ bgColorOne, bgColorTwo, ...props }) => (
  <Container>
    <Dual bgColorOne={bgColorOne} bgColorTwo={bgColorTwo} {...props}>
      <span></span>
    </Dual>
  </Container>
)

const ColorSwatch = ({ color, ...props }) => {
  switch (color) {
    case "100-White":
      return <SimpleSwatch {...props} bgColor="white" />
    case "099-Black":
      return <SimpleSwatch {...props} bgColor="black" />
    case "101-Off-White":
      return <SimpleSwatch {...props} bgColor="offWhite" />
    case "999-Black/Black":
      return <DualSwatch {...props} bgColorOne="black" bgColorTwo="black" />
    case "990-Black/White":
      return <DualSwatch {...props} bgColorOne="black" bgColorTwo="white" />
    default:
      return null
  }
}

export default ColorSwatch
