import React, { useContext } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Dinero from "dinero.js"
import { BrinkContext } from "../context/BrinkContext"

const Container = styled.div`
  width: 100%;
`

const Row = styled.div`
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  height: 5.1rem;
  justify-content: space-between;
  margin: 0;
  font-size: 1.3rem;
  padding-bottom: 0.1rem;
`

const GrandTotal = styled(Row)`
  font-size: 1.5rem;
  font-weight: 700;
  border-bottom: none;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
`

const LinePrice = styled.span`
  display: block;

  p {
    font-size: 1.4rem;
    margin: 0;
  }
`

const DiscountPrice = styled(LinePrice)`
  &:before {
    content: "-";
    margin-right: 0.2rem;
  }
`

const Total = styled(LinePrice)`
  p {
    margin: 0;
  }
`

const Totals = ({ hideShipping, ...rest }) => {
  const { t } = useTranslation("translations")
  const { cart, languageCode, currentStore } = useContext(BrinkContext)
  const { currencyUnit } = currentStore
  const shipping = cart.cartItems.filter(
    (item) => item.type === "shippingOption"
  )

  return (
    <Container {...rest}>
      {cart.totalDiscountAmount !== 0 && (
        <Row>
          <span>{t("Discount")}</span>
          <DiscountPrice>
            {toDinero(cart.totalDiscountAmount || 0, currencyUnit).toFormat()}
          </DiscountPrice>
        </Row>
      )}
      {!hideShipping && shipping.length > 0 && (
        <Row>
          <span>
            {t("Shipping")} ({shipping[0].attribute.displayName[languageCode]})
          </span>
          <LinePrice>
            {toDinero(
              shipping[0].price[currentStore.currencyUnit] || 0,
              currencyUnit
            ).toFormat()}
          </LinePrice>
        </Row>
      )}
      <GrandTotal>
        <span>{t("Grand total")}</span>
        <Total>
          {toDinero(cart.totalPriceWithDiscount || 0, currencyUnit).toFormat()}
        </Total>
      </GrandTotal>
    </Container>
  )
}

const toDinero = (amount, currencyUnit) => {
  return new Dinero({ amount: amount, currency: currencyUnit })
}

export default Totals
